<template>
  <div class="info" style="display: block">
    <span class="info__creator"
      >{{ artGuide.created_by_text
      }}<span v-show="dates" class="info__separator" style="margin-right: 0">, </span></span
    >
    <span v-show="dates" class="info__dates">{{ dates }}</span>
  </div>
</template>

<script>
export default {
  name: 'ArtGuideCreatorDatesInfo',
  props: {
    artGuide: {
      type: Object,
      required: true,
      default: () => ({ id: null, dates: [], created_by_text: '' }),
    },
  },
  computed: {
    dates() {
      if (this.artGuide.dates.length !== 2) {
        return '';
      }

      const earliestDate = this.$moment(this.artGuide.dates[0]);
      const latestDate = this.$moment(this.artGuide.dates[1]);

      if (!earliestDate || !latestDate) {
        return '';
      }

      let earliestDateFormat = 'DD MMM YYYY';
      let latestDateFormat = 'DD MMM YYYY';

      if (earliestDate.year() === latestDate.year()) {
        earliestDateFormat = 'DD MMM';

        if (earliestDate.month() === latestDate.month()) {
          earliestDateFormat = 'DD';
          latestDateFormat = 'DD MMMM YYYY';
        }
      }

      return earliestDate.format(earliestDateFormat) + ' - ' + latestDate.format(latestDateFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;

  &__separator {
    margin-right: 3px;
  }
}
</style>
