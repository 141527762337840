








































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import artGuidesDataQuery from '@/graphql/artGuide/ArtGuides.query.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import MyArtGuidesPageItem from '@/components/partials/MyArtGuidesPageItem.vue';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const PER_PAGE = 6;

@Component({
  name: 'MyArtGuidesPage',
  components: {
    MsgEntityCollectionEmpty,
    BackHeader,
    MyArtGuidesPageItem,
    AddToGuideModal: () => import('@/components/partials/AddToGuideModal.vue'),
  },
  metaInfo: {
    title: 'My Art Guides',
  },
  apollo: {
    artGuidesData: {
      query: artGuidesDataQuery,
      variables() {
        return {
          limit: PER_PAGE,
          nextCursor: this.nextCursor,
        };
      },
      result(result: any) {
        this.artGuides.push(...result.data.artGuidesData.data);
        this.loading = false;

        const loadState = this.$refs.infiniteLoad.stateChanger;

        if (this.artGuidesData.hasMorePages) {
          loadState.loaded();
        } else {
          loadState.complete();
        }
      },
    },
  },
  computed: {
    ...mapState(['museumRole']),
  },
})
export default class MyArtGuidesPage extends Vue {
  public artGuidesData: any;
  public artGuides: any[] = [];

  public loading = true;
  public nextCursor: string | null = null;
  private isShowCreateModal = false;

  loadMore(state: any) {
    if (this.artGuidesData && this.artGuidesData.hasMorePages) {
      this.loading = true;
      this.nextCursor = this.artGuidesData.nextCursor;
      return;
    }
    state.complete();
  }

  toggleCreateModal() {
    this.isShowCreateModal = !this.isShowCreateModal;

    if (this.isShowCreateModal) {
      this.$store.dispatch('disableScroll');
    } else {
      this.$store.dispatch('enableScroll');
    }
  }

  afterArtGuideCreated(guide: any) {
    if (guide) {
      this.artGuides.unshift(guide);
    }
  }
}
